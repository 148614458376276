
















































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      form: {
        email: '',
      },
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: 'getErrorMessage',
      successMessage: 'getSuccessMessage',
    }),
  },
  methods: {
    async submitForm() {
      const { email } = this.form;
      await this.$store.dispatch('auth/resetPassword', email);
      this.form = { email: '' };
    },
  },
});
